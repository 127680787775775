exports.components = {
  "component---src-components-default-blog-index-layout-js": () => import("./../../../src/components/default-blog-index-layout.js" /* webpackChunkName: "component---src-components-default-blog-index-layout-js" */),
  "component---src-components-default-blog-layout-js": () => import("./../../../src/components/default-blog-layout.js" /* webpackChunkName: "component---src-components-default-blog-layout-js" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2019-06-29-shubhaarmbh-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2019-06-29-shubhaarmbh.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2019-06-29-shubhaarmbh-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2019-07-13-shell-architecture-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2019-07-13-shell-architecture.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2019-07-13-shell-architecture-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2019-07-26-tattle-data-science-finding-similar-videos-efficiently-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2019-07-26-tattle-data-science-finding-similar-videos-efficiently.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2019-07-26-tattle-data-science-finding-similar-videos-efficiently-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2019-11-06-learning-from-una-hakika-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2019-11-06-learning-from-una-hakika.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2019-11-06-learning-from-una-hakika-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2019-11-12-establishing-conventions-for-ui-engineering-with-react-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2019-11-12-establishing-conventions-for-ui-engineering-with-react.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2019-11-12-establishing-conventions-for-ui-engineering-with-react-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2019-11-15-scraping-fact-checked-news-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2019-11-15-scraping-fact-checked-news.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2019-11-15-scraping-fact-checked-news-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-03-10-introducing-fact-check-search-v-1-0-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-03-10-introducing-fact-check-search-v1-0.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-03-10-introducing-fact-check-search-v-1-0-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-03-16-stakeholders-in-the-indian-misinformation-ecosystem-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-03-16-stakeholders-in-the-indian-misinformation-ecosystem.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-03-16-stakeholders-in-the-indian-misinformation-ecosystem-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-05-22-topic-modelling-on-fact-checked-stories-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-05-22-topic-modelling-on-fact-checked-stories.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-05-22-topic-modelling-on-fact-checked-stories-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-05-25-analysing-the-katna-library-for-video-key-frame-extraction-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-05-25-analysing-the-katna-library-for-video-key-frame-extraction.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-05-25-analysing-the-katna-library-for-video-key-frame-extraction-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-05-25-archiving-data-from-sharechat-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-05-25-archiving-data-from-sharechat.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-05-25-archiving-data-from-sharechat-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-07-08-fake-news-amplification-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-07-08-fake-news-amplification.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-07-08-fake-news-amplification-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-08-03-a-brief-intro-to-gpt-3-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-08-03-a-brief-intro-to-gpt-3.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-08-03-a-brief-intro-to-gpt-3-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-08-08-deepfakes-cheap-fakes-and-misinformation-in-india-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-08-08-deepfakes-cheap-fakes-and-misinformation-in-india.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-08-08-deepfakes-cheap-fakes-and-misinformation-in-india-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-08-14-multimodal-fusion-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-08-14-multimodal-fusion.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-08-14-multimodal-fusion-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-09-11-the-use-of-archives-in-misinformation-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-09-11-the-use-of-archives-in-misinformation.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-09-11-the-use-of-archives-in-misinformation-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-09-18-anek-aayaam-vibhin-paigaam-ek-vaairl-photto-kii-khaanii-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-09-18-anek-aayaam-vibhin-paigaam-ek-vaairl-photto-kii-khaanii.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-09-18-anek-aayaam-vibhin-paigaam-ek-vaairl-photto-kii-khaanii-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-09-24-the-role-of-affect-in-misinformation-circulation-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-09-24-the-role-of-affect-in-misinformation-circulation.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-09-24-the-role-of-affect-in-misinformation-circulation-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-10-09-the-unexplained-motivation-behind-whatsapp-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-10-09-the-unexplained-motivation-behind-whatsapp.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-10-09-the-unexplained-motivation-behind-whatsapp-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-10-23-learning-from-the-user-usage-model-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-10-23-learning-from-the-user-usage-model.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-10-23-learning-from-the-user-usage-model-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-10-30-clustering-similar-images-with-phash-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2020-10-30-clustering-similar-images-with-phash.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2020-10-30-clustering-similar-images-with-phash-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2021-01-10-the-need-for-archiving-social-media-data-and-the-conundrums-of-opening-it-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2021-01-10-the-need-for-archiving-social-media-data-and-the-conundrums-of-opening-it.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2021-01-10-the-need-for-archiving-social-media-data-and-the-conundrums-of-opening-it-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2021-08-01-thinking-about-feminist-approaches-to-technology-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2021-08-01-thinking-about-feminist-approaches-to-technology.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2021-08-01-thinking-about-feminist-approaches-to-technology-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2021-12-15-making-of-uli-december-2021-newsletter-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2021-12-15-making-of-uli-december-2021-newsletter.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2021-12-15-making-of-uli-december-2021-newsletter-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2022-01-15-making-of-uli-january-2022-newsletter-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2022-01-15-making-of-uli-january-2022-newsletter.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2022-01-15-making-of-uli-january-2022-newsletter-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2022-02-15-making-of-uli-february-2022-newsletter-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2022-02-15-making-of-uli-february-2022-newsletter.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2022-02-15-making-of-uli-february-2022-newsletter-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2022-04-16-responsible-way-of-showcasing-the-countering-of-ogbv-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2022-04-16-responsible-way-of-showcasing-the-countering-of-ogbv.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2022-04-16-responsible-way-of-showcasing-the-countering-of-ogbv-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2022-04-18-lessons-in-user-research-from-donald-lobo-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2022-04-18-lessons-in-user-research-from-donald-lobo.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2022-04-18-lessons-in-user-research-from-donald-lobo-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2022-04-26-making-of-uli-march-2022-newsletter-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2022-04-26-making-of-uli-march-2022-newsletter.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2022-04-26-making-of-uli-march-2022-newsletter-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2022-07-15-privacy-preserving-features-of-uli-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2022-07-15-privacy-preserving-features-of-uli.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2022-07-15-privacy-preserving-features-of-uli-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2022-08-28-getting-started-with-aws-copilot-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2022-08-28-getting-started-with-aws-copilot.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2022-08-28-getting-started-with-aws-copilot-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2023-07-09-making-of-uli-august-2022-newsletter-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2023-07-09-making-of-uli-august-2022-newsletter.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2023-07-09-making-of-uli-august-2022-newsletter-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2023-09-24-opensource-ai-scraping-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2023-09-24-opensourceAI_scraping.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2023-09-24-opensource-ai-scraping-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2023-09-25-effective-response-closed-messaging-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2023-09-25-effective_response_closed_messaging.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2023-09-25-effective-response-closed-messaging-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2023-12-17-sprint-dmm-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2023-12-17-sprint-dmm.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2023-12-17-sprint-dmm-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2023-12-22-making-of-uli-dec-2023-newsletter-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2023-12-22-making-of-uli-dec-2023-newsletter.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2023-12-22-making-of-uli-dec-2023-newsletter-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-03-16-cross-platform-pt-1-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2024-03-16-cross-platform-pt1.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-03-16-cross-platform-pt-1-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-04-03-securing-feluda-pt-1-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2024-04-03-securing-feluda-pt1.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-04-03-securing-feluda-pt-1-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-04-17-crossplatform-pt-2-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2024-04-17-crossplatform-pt2.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-04-17-crossplatform-pt-2-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-04-24-silencing-impact-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2024-04-24-silencing-impact.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-04-24-silencing-impact-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-05-02-chisel-online-abuse-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2024-05-02-chisel-online-abuse.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-05-02-chisel-online-abuse-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-05-09-slurs-occur-every-language-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2024-05-09-slurs-occur-every-language.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-05-09-slurs-occur-every-language-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-05-16-context-matters-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2024-05-16-context-matters.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-05-16-context-matters-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-05-23-collective-journey-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2024-05-23-collective-journey.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-05-23-collective-journey-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-08-19-looking-beyond-the-deepfakes-moral-panic-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/2024-08-19-looking-beyond-the-deepfakes-moral-panic.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-2024-08-19-looking-beyond-the-deepfakes-moral-panic-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-a-new-thing-under-the-sun-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/a-new-thing-under-the-sun.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-a-new-thing-under-the-sun-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-advantages-of-platform-cooperatives-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/advantages-of-platform-cooperatives.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-advantages-of-platform-cooperatives-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-archive-ml-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/archive-ml.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-archive-ml-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-considerations-in-archiving-misinformation-fromencrypted-messaging-apps-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/considerations-in-archiving-misinformation-fromencrypted-messaging-apps.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-considerations-in-archiving-misinformation-fromencrypted-messaging-apps-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-content-creation-for-the-meshi-information-sharing-experiment-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/content-creation-for-the-meshi-information-sharing-experiment.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-content-creation-for-the-meshi-information-sharing-experiment-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-designing-the-meshi-platform-for-the-monk-prayogshala-study-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/designing-the-meshi-platform-for-the-monk-prayogshala-study.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-designing-the-meshi-platform-for-the-monk-prayogshala-study-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-exploring-contentious-ideas-with-art-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/exploring-contentious-ideas-with-art.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-exploring-contentious-ideas-with-art-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-factshala-project-reading-list-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/factshala-project-reading-list.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-factshala-project-reading-list-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-feluda-featured-dpga-report-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/feluda-featured-dpga-report.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-feluda-featured-dpga-report-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-feluda-recognised-digital-public-good-dpga-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/feluda-recognised-digital-public-good-dpga.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-feluda-recognised-digital-public-good-dpga-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-flutter-and-shaders-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/flutter-and-shaders.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-flutter-and-shaders-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-handpicked-issues-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/handpicked-issues.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-handpicked-issues-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-how-to-set-a-playtest-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/how-to-set-a-playtest.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-how-to-set-a-playtest-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-how-to-setup-uli-locally-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/how-to-setup-uli-locally.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-how-to-setup-uli-locally-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-media-literacy-in-classrooms-insights-from-instructors-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/media-literacy-in-classrooms-insights-from-instructors.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-media-literacy-in-classrooms-insights-from-instructors-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-meta-hidden-words-instagram-threads-uli-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/meta-hidden-words-instagram-threads-uli.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-meta-hidden-words-instagram-threads-uli-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-misinfocon-india-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/misinfocon-india.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-misinfocon-india-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-misinfocon-india-working-groups-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/misinfocon-india-working-groups.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-misinfocon-india-working-groups-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-mitigating-harms-of-synthetic-media-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/mitigating-harms-of-synthetic-media.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-mitigating-harms-of-synthetic-media-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-onosaid-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/onosaid.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-onosaid-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-scraping-legal-ethics-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/scraping-legal-ethics.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-scraping-legal-ethics-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-show-and-tell-sep-24-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/show-and-tell-sep-24.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-show-and-tell-sep-24-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-social-media-companies-politics-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/social-media-companies-politics.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-social-media-companies-politics-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-sqrl-stateful-systems-end-user-content-moderation-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/sqrl-stateful-systems-end-user-content-moderation.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-sqrl-stateful-systems-end-user-content-moderation-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-tattles-take-on-the-indian-telecom-bill-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/tattles-take-on-the-indian-telecom-bill.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-tattles-take-on-the-indian-telecom-bill-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-the-games-we-play-online-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/the-games-we-play-online.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-the-games-we-play-online-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-the-need-futility-of-archiving-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/the-need-futility-of-archiving.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-the-need-futility-of-archiving-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-uli-chatgpt-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/uli-chatgpt.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-uli-chatgpt-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-uli-e-2-e-testing-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/uli-e2e-testing.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-uli-e-2-e-testing-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-uli-generative-ai-metaphor-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/uli-generative-ai-metaphor.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-uli-generative-ai-metaphor-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-understanding-viral-spiral-project-page-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/understanding-viral-spiral-project-page.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-understanding-viral-spiral-project-page-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-unesco-guidelines-on-platform-regulation-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/unesco-guidelines-on-platform-regulation.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-unesco-guidelines-on-platform-regulation-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-video-recording-adms-symposium-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/video-recording-adms-symposium.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-video-recording-adms-symposium-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-viral-spiral-reading-list-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/viral-spiral-reading-list.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-viral-spiral-reading-list-mdx" */),
  "component---src-components-default-blog-layout-js-content-file-path-src-blog-why-we-built-ulis-redaction-of-slurs-feature-mdx": () => import("./../../../src/components/default-blog-layout.js?__contentFilePath=/home/runner/work/website/website/src/blog/why-we-built-ulis-redaction-of-slurs-feature.mdx" /* webpackChunkName: "component---src-components-default-blog-layout-js-content-file-path-src-blog-why-we-built-ulis-redaction-of-slurs-feature-mdx" */),
  "component---src-components-default-people-layout-js": () => import("./../../../src/components/default-people-layout.js" /* webpackChunkName: "component---src-components-default-people-layout-js" */),
  "component---src-components-default-sitemap-layout-js": () => import("./../../../src/components/default-sitemap-layout.js" /* webpackChunkName: "component---src-components-default-sitemap-layout-js" */),
  "component---src-components-default-tag-page-layout-js": () => import("./../../../src/components/default-tag-page-layout.js" /* webpackChunkName: "component---src-components-default-tag-page-layout-js" */),
  "component---src-components-default-tag-project-page-layout-js": () => import("./../../../src/components/default-tag-project-page-layout.js" /* webpackChunkName: "component---src-components-default-tag-project-page-layout-js" */),
  "component---src-components-default-updates-project-tag-page-layout-js": () => import("./../../../src/components/default-updates-project-tag-page-layout.js" /* webpackChunkName: "component---src-components-default-updates-project-tag-page-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-covid-whatsapp-public-groups-index-2-mdx": () => import("./../../../src/pages/articles/covid-whatsapp-public-groups/index-2.mdx" /* webpackChunkName: "component---src-pages-articles-covid-whatsapp-public-groups-index-2-mdx" */),
  "component---src-pages-articles-covid-whatsapp-public-groups-index-jsx": () => import("./../../../src/pages/articles/covid-whatsapp-public-groups/index.jsx" /* webpackChunkName: "component---src-pages-articles-covid-whatsapp-public-groups-index-jsx" */),
  "component---src-pages-articles-covid-whatsapp-public-groups-t-sne-jsx": () => import("./../../../src/pages/articles/covid-whatsapp-public-groups/t-sne.jsx" /* webpackChunkName: "component---src-pages-articles-covid-whatsapp-public-groups-t-sne-jsx" */),
  "component---src-pages-articles-vaccine-hesitancy-index-mdx": () => import("./../../../src/pages/articles/vaccine-hesitancy/index.mdx" /* webpackChunkName: "component---src-pages-articles-vaccine-hesitancy-index-mdx" */),
  "component---src-pages-career-backend-engineer-mdx": () => import("./../../../src/pages/career/backend-engineer.mdx" /* webpackChunkName: "component---src-pages-career-backend-engineer-mdx" */),
  "component---src-pages-career-communications-specialist-mdx": () => import("./../../../src/pages/career/communications-specialist.mdx" /* webpackChunkName: "component---src-pages-career-communications-specialist-mdx" */),
  "component---src-pages-career-contribute-mdx": () => import("./../../../src/pages/career/contribute.mdx" /* webpackChunkName: "component---src-pages-career-contribute-mdx" */),
  "component---src-pages-career-datascience-ogbv-mdx": () => import("./../../../src/pages/career/datascience_ogbv.mdx" /* webpackChunkName: "component---src-pages-career-datascience-ogbv-mdx" */),
  "component---src-pages-career-digitalmarketing-consultant-mdx": () => import("./../../../src/pages/career/digitalmarketing-consultant.mdx" /* webpackChunkName: "component---src-pages-career-digitalmarketing-consultant-mdx" */),
  "component---src-pages-career-fullstack-mdx": () => import("./../../../src/pages/career/fullstack.mdx" /* webpackChunkName: "component---src-pages-career-fullstack-mdx" */),
  "component---src-pages-career-qualitative-researcher-mdx": () => import("./../../../src/pages/career/qualitative-researcher.mdx" /* webpackChunkName: "component---src-pages-career-qualitative-researcher-mdx" */),
  "component---src-pages-career-research-associate-mdx": () => import("./../../../src/pages/career/research-associate.mdx" /* webpackChunkName: "component---src-pages-career-research-associate-mdx" */),
  "component---src-pages-career-sde-1-mdx": () => import("./../../../src/pages/career/sde1.mdx" /* webpackChunkName: "component---src-pages-career-sde-1-mdx" */),
  "component---src-pages-career-senior-fullstack-developer-mdx": () => import("./../../../src/pages/career/senior-fullstack-developer.mdx" /* webpackChunkName: "component---src-pages-career-senior-fullstack-developer-mdx" */),
  "component---src-pages-career-uli-program-manager-mdx": () => import("./../../../src/pages/career/uli-program-manager.mdx" /* webpackChunkName: "component---src-pages-career-uli-program-manager-mdx" */),
  "component---src-pages-career-uli-research-1-mdx": () => import("./../../../src/pages/career/uli-research-1.mdx" /* webpackChunkName: "component---src-pages-career-uli-research-1-mdx" */),
  "component---src-pages-career-uli-research-2-mdx": () => import("./../../../src/pages/career/uli-research-2.mdx" /* webpackChunkName: "component---src-pages-career-uli-research-2-mdx" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-contributors-js": () => import("./../../../src/pages/contributors.js" /* webpackChunkName: "component---src-pages-contributors-js" */),
  "component---src-pages-datasets-datasets-we-love-mdx": () => import("./../../../src/pages/datasets/datasets-we-love.mdx" /* webpackChunkName: "component---src-pages-datasets-datasets-we-love-mdx" */),
  "component---src-pages-datasets-index-js": () => import("./../../../src/pages/datasets/index.js" /* webpackChunkName: "component---src-pages-datasets-index-js" */),
  "component---src-pages-faq-mdx": () => import("./../../../src/pages/faq.mdx" /* webpackChunkName: "component---src-pages-faq-mdx" */),
  "component---src-pages-fullstack-mdx": () => import("./../../../src/pages/fullstack.mdx" /* webpackChunkName: "component---src-pages-fullstack-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-us-mdx": () => import("./../../../src/pages/join-us.mdx" /* webpackChunkName: "component---src-pages-join-us-mdx" */),
  "component---src-pages-lab-topic-modeling-factcheck-articles-mdx": () => import("./../../../src/pages/lab/topic-modeling-factcheck-articles.mdx" /* webpackChunkName: "component---src-pages-lab-topic-modeling-factcheck-articles-mdx" */),
  "component---src-pages-lang-hindi-faq-hindi-mdx": () => import("./../../../src/pages/lang/hindi/faq_hindi.mdx" /* webpackChunkName: "component---src-pages-lang-hindi-faq-hindi-mdx" */),
  "component---src-pages-lang-hindi-values-hindi-mdx": () => import("./../../../src/pages/lang/hindi/values_hindi.mdx" /* webpackChunkName: "component---src-pages-lang-hindi-values-hindi-mdx" */),
  "component---src-pages-lang-malayalam-faq-malayalam-mdx": () => import("./../../../src/pages/lang/malayalam/faq_malayalam.mdx" /* webpackChunkName: "component---src-pages-lang-malayalam-faq-malayalam-mdx" */),
  "component---src-pages-lang-malayalam-values-malayalam-mdx": () => import("./../../../src/pages/lang/malayalam/values_malayalam.mdx" /* webpackChunkName: "component---src-pages-lang-malayalam-values-malayalam-mdx" */),
  "component---src-pages-lang-marathi-faq-marathi-mdx": () => import("./../../../src/pages/lang/marathi/faq_marathi.mdx" /* webpackChunkName: "component---src-pages-lang-marathi-faq-marathi-mdx" */),
  "component---src-pages-lang-marathi-values-marathi-mdx": () => import("./../../../src/pages/lang/marathi/values_marathi.mdx" /* webpackChunkName: "component---src-pages-lang-marathi-values-marathi-mdx" */),
  "component---src-pages-lang-tamil-faq-tamil-mdx": () => import("./../../../src/pages/lang/tamil/faq_tamil.mdx" /* webpackChunkName: "component---src-pages-lang-tamil-faq-tamil-mdx" */),
  "component---src-pages-lang-tamil-values-tamil-mdx": () => import("./../../../src/pages/lang/tamil/values_tamil.mdx" /* webpackChunkName: "component---src-pages-lang-tamil-values-tamil-mdx" */),
  "component---src-pages-lang-telugu-faq-telugu-mdx": () => import("./../../../src/pages/lang/telugu/faq_telugu.mdx" /* webpackChunkName: "component---src-pages-lang-telugu-faq-telugu-mdx" */),
  "component---src-pages-lang-telugu-values-telugu-mdx": () => import("./../../../src/pages/lang/telugu/values_telugu.mdx" /* webpackChunkName: "component---src-pages-lang-telugu-values-telugu-mdx" */),
  "component---src-pages-links-jsx": () => import("./../../../src/pages/links.jsx" /* webpackChunkName: "component---src-pages-links-jsx" */),
  "component---src-pages-misinfocon-india-mdx": () => import("./../../../src/pages/misinfocon-india.mdx" /* webpackChunkName: "component---src-pages-misinfocon-india-mdx" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../../../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-products-closed-messaging-checklist-mdx": () => import("./../../../src/pages/products/closed-messaging-checklist.mdx" /* webpackChunkName: "component---src-pages-products-closed-messaging-checklist-mdx" */),
  "component---src-pages-products-dau-mdx": () => import("./../../../src/pages/products/dau.mdx" /* webpackChunkName: "component---src-pages-products-dau-mdx" */),
  "component---src-pages-products-facts-jsx": () => import("./../../../src/pages/products/facts.jsx" /* webpackChunkName: "component---src-pages-products-facts-jsx" */),
  "component---src-pages-products-feluda-jsx": () => import("./../../../src/pages/products/feluda.jsx" /* webpackChunkName: "component---src-pages-products-feluda-jsx" */),
  "component---src-pages-products-gftw-mdx": () => import("./../../../src/pages/products/gftw.mdx" /* webpackChunkName: "component---src-pages-products-gftw-mdx" */),
  "component---src-pages-products-github-indices-mdx": () => import("./../../../src/pages/products/github-indices.mdx" /* webpackChunkName: "component---src-pages-products-github-indices-mdx" */),
  "component---src-pages-products-index-jsx": () => import("./../../../src/pages/products/index.jsx" /* webpackChunkName: "component---src-pages-products-index-jsx" */),
  "component---src-pages-products-jod-bot-mdx": () => import("./../../../src/pages/products/jod-bot.mdx" /* webpackChunkName: "component---src-pages-products-jod-bot-mdx" */),
  "component---src-pages-products-khoj-mdx": () => import("./../../../src/pages/products/khoj.mdx" /* webpackChunkName: "component---src-pages-products-khoj-mdx" */),
  "component---src-pages-products-kosh-jsx": () => import("./../../../src/pages/products/kosh.jsx" /* webpackChunkName: "component---src-pages-products-kosh-jsx" */),
  "component---src-pages-products-ogbv-jsx": () => import("./../../../src/pages/products/ogbv.jsx" /* webpackChunkName: "component---src-pages-products-ogbv-jsx" */),
  "component---src-pages-products-viral-spiral-index-jsx": () => import("./../../../src/pages/products/viral-spiral/index.jsx" /* webpackChunkName: "component---src-pages-products-viral-spiral-index-jsx" */),
  "component---src-pages-products-viral-spiral-presskit-mdx": () => import("./../../../src/pages/products/viral-spiral/presskit.mdx" /* webpackChunkName: "component---src-pages-products-viral-spiral-presskit-mdx" */),
  "component---src-pages-products-whatsapp-archiver-mdx": () => import("./../../../src/pages/products/whatsapp-archiver.mdx" /* webpackChunkName: "component---src-pages-products-whatsapp-archiver-mdx" */),
  "component---src-pages-report-2019-report-mdx": () => import("./../../../src/pages/report/2019-report.mdx" /* webpackChunkName: "component---src-pages-report-2019-report-mdx" */),
  "component---src-pages-report-2020-report-mdx": () => import("./../../../src/pages/report/2020-report.mdx" /* webpackChunkName: "component---src-pages-report-2020-report-mdx" */),
  "component---src-pages-report-2021-report-mdx": () => import("./../../../src/pages/report/2021-report.mdx" /* webpackChunkName: "component---src-pages-report-2021-report-mdx" */),
  "component---src-pages-report-2022-report-mdx": () => import("./../../../src/pages/report/2022-report.mdx" /* webpackChunkName: "component---src-pages-report-2022-report-mdx" */),
  "component---src-pages-report-index-mdx": () => import("./../../../src/pages/report/index.mdx" /* webpackChunkName: "component---src-pages-report-index-mdx" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-theme-js": () => import("./../../../src/pages/theme.js" /* webpackChunkName: "component---src-pages-theme-js" */),
  "component---src-pages-updates-js": () => import("./../../../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-pages-v-2-case-study-preview-js": () => import("./../../../src/pages/v2/case-study-preview.js" /* webpackChunkName: "component---src-pages-v-2-case-study-preview-js" */),
  "component---src-pages-v-2-footer-js": () => import("./../../../src/pages/v2/footer.js" /* webpackChunkName: "component---src-pages-v-2-footer-js" */),
  "component---src-pages-v-2-index-js": () => import("./../../../src/pages/v2/index.js" /* webpackChunkName: "component---src-pages-v-2-index-js" */),
  "component---src-pages-v-2-section-wrapper-js": () => import("./../../../src/pages/v2/section-wrapper.js" /* webpackChunkName: "component---src-pages-v-2-section-wrapper-js" */),
  "component---src-pages-v-2-we-build-for-js": () => import("./../../../src/pages/v2/we-build-for.js" /* webpackChunkName: "component---src-pages-v-2-we-build-for-js" */),
  "component---src-pages-values-js": () => import("./../../../src/pages/values.js" /* webpackChunkName: "component---src-pages-values-js" */),
  "component---src-pages-video-mdx": () => import("./../../../src/pages/video.mdx" /* webpackChunkName: "component---src-pages-video-mdx" */)
}

